import React from 'react';

function ItemPage() {
    return (
        <div className="itemPage">
            <h1>How many you want, huh??</h1>
        </div>
    )
}

export default ItemPage
